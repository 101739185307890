/*
========================================================================||
Builder page
- templating so that we can create as many builder pages as we want
  via contentful CMS

Supported modules:
1) Hero
2) Flexible column(s)

========================================================================||
*/

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// Components
import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';
import BuilderApp from '../components/app-builder';

const BuilderTemplate = ({ data }) => {
  const params = data.allContentfulBuilderPage.nodes[0];

  return (
    <Layout>
      <Seo
        title={params?.seoTitle || params.title}
        description={params?.seoDescription || ''}
      />
      <Hero
        title={params.heroTitle || params.title}
        description={params?.heroDescription?.heroDescription}
        ctas={params.heroCtas}
        image={params.heroImage}
        tagline={params.heroTagline}
      />
      {params?.flexibleColumns?.length > 0 && (
        <BuilderApp columns={params.flexibleColumns} />
      )}
    </Layout>
  );
};

BuilderTemplate.defaultProps = {
  data: {},
};

BuilderTemplate.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query ($slug: String!) {
    allContentfulBuilderPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        seoTitle
        seoDescription
        heroTitle
        heroDescription {
          heroDescription
        }
        heroCtas {
          ...buttonFragment
        }
        heroImage {
          title
          description
          gatsbyImageData(formats: [WEBP, JPG], placeholder: BLURRED)
        }
        heroTagline
        ...builderPageFlexColFragment
      }
    }
  }
`;

export default BuilderTemplate;
